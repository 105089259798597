import { Route, Routes as BaseRoutes } from "react-router-dom";
import Home from "./pages/home";
import Subtitle from './pages/subtitle';
import ErrorPage from './pages/error';
import NotFound from './pages/notFound';

export default function Routes() {
  return (
    <BaseRoutes>
      <Route path="/" element={<Home />} />
      {/* <Route path="/subtitle" element={<Subtitle />} /> */}
      <Route path="/s/:backhalf_url" element={<Subtitle />} />
      <Route path="/error/:errorCode" element={<ErrorPage />} />
      <Route path="*" element={<NotFound errorCode="404" />} />
    </BaseRoutes>
  );
}