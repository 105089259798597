import Routes from './routes'
import { useEffect, useState } from 'react';

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false)

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark') 
    }
  }, [isDarkMode])

  return (
    <main className={`dark:text-white dark:bg-[#00001f]`}>
      <Routes/>
    </main>
  );
}

export default App;