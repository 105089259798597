import React, { useEffect, useState } from 'react';
import { getSubtitleSentences } from '../services/subtitle';
import { useNavigate, useParams } from 'react-router-dom';

const Subtitle = () => {
  let { backhalf_url } = useParams();
  const [data, setData] = useState(null);
  const [fileTitle, setFileTitle] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getSubtitleSentences(backhalf_url);

        if (!result) {
          navigate('/error/404');
        }
        setFileTitle(result.subtitle.title)
        setData(result.subtitle.rawLines)
      } catch {
        navigate('/error/500');
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setIsScrolled(currentScrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="">
      <div className={`bg-white dark:bg-[#0000001f] py-4 ${isScrolled ? 'fixed top-0 w-full' : ''}`}>
        <header
          className={`flex flex-col sm:max-w-[70%] max-w-[90%] justify-center m-auto h-20`}
        >
            <p className='font-bold text-3xl' onClick={() => navigate('/')}>영문 추출기</p>
            <p className="text-sm text-gray-500 mt-1">discover & learn</p>
        </header>
      </div>
      <div className='korean-text'>
        <div className='flex items-center sm:max-w-[70%] w-[90%] m-auto break-all'>
          <article className='space-y-6'>
            {/* <div className='font-medium text-2xl pt-4'>{fileTitle}</div> */}
            {data && data.map((line, index) => {
              return (
                // <div className={`space-y-2 border-l-2 pl-8 ${index % 2 === 0 ? 'border-blue-500' : 'border-green-500'}`}>
                <div key={index}>
                  {/* <p className='font-light text-gray-500'>{index}</p> */}
                  <p className='font-medium text-xl' key={index}>{line}</p>
                  <hr/>
                </div>
                // </div>
              )
            })}
          </article>
        </div>
      </div>
    </div>
  );
}

export default Subtitle;