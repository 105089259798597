import axios from 'axios';

export const getSubtitleSentences = async (backhalf_url) => {
	try {
		// TODO error handling
		const response = await axios.get(process.env.REACT_APP_URL+`/${backhalf_url}`)
		return response.data;
	} catch (error) {
		console.log("Error fetching subtitle sentences:", error);
		return ;
	}
}