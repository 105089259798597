import React, { useState } from 'react';
import { FaLock } from 'react-icons/fa';

import axios from 'axios';


function Home() {
	const [fileName, setFileName] = useState('');
	const [url, setUrl] = useState('');
	const [inputValue, setInputValue] = useState('');
	const fileInputRef = React.createRef();

	const handleFileUpload = (e) => {
		// 기본 동작을 막는다.
		e.preventDefault();

		// 이벤트 타입에 따라서 파일을 가져온다.
		let file;
		if (e.type === 'drop') {
			file = e.dataTransfer.files[0];
		} else if (e.type === 'change') {
			file = e.target.files[0];
		}

		// 파일이 없으면 종료
		if (file) {
			const fileSize = file.size / 1024 / 1024;
			const fileType = file.name.split('.').pop();
			if (fileType !== 'srt') {
				alert('자막파일인 srt 파일만 업로드 가능합니다.');
				return;
			}

			if (fileSize > 5) {
				alert('파일 용량이 5MB를 초과하였습니다. 5MB 이하의 파일만 업로드 가능합니다.');
			}

			setFileName(file.name);
		}
	}

	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const handleClick = () => {
		// 클릭 이벤트가 발생하면 input file의 클릭 이벤트를 발생시킨다.
		fileInputRef.current.click();
	}

	const handleInputChange = (event) => {
		setInputValue(event.target.value);
		// console.log(event.target.value);
		// console.log(fileInputRef.current.files[0]);
	};

	const extractSubtitle = async () => {
		console.log('extractSubtitle');
		const formData = new FormData();
		if (!formData.has('srtfile') && !fileInputRef.current.files[0]) {
			alert('자막파일이 누락되었습니다.')
			return;
		} else if (inputValue === '') {
			//TODO : backhalf 중복 시 에러 처리
			alert('back-half 값이 누락되었습니다.')
			return;
		}

		formData.append('srtfile', fileInputRef.current.files[0]);
		formData.append('url', inputValue)

		// TODO : change get to post, v1/wines to v1/subtitle
		const response = await axios.post(process.env.REACT_APP_URL + "/subtitle/parse", formData);
		setUrl(response.data.customUrl.customPath)
	};

	return (
		<div className='h-full korean-text px-4 sm:px-0'>
			<div className='flex flex-col justify-center items-center min-h-screen sm:space-y-4 transform sm:-translate-y-16'>
				<div className='-translate-y-8'>
					<h1 className='title font-extrabold text-3xl text-center mb-4'>
						영어 문장 추출기
					</h1>
					{/* <p className='font-light text-xl text-center text-gray-400 sm:block hidden'>Discover and Learn</p> */}
					<p className='font-light text-xl text-center text-gray-400 -translate-y-3'> 영어 자막 속 표현들을 만나다</p>
				</div>
				<section
					htmlFor="dropzone-file"
					className={`mx-auto cursor-pointer flex w-full max-w-2xl flex-col items-center rounded-xl border-2 border-dashed border-gray-400 /bg-[#f5f6f7] dark:bg-white p-6 text-center`}
					onDrop={handleFileUpload}
					onDragOver={handleDragOver}
					onClick={handleClick}
				>
					<svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
						<path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
					</svg>
					<h2 className="mt-4 text-xl font-medium text-black tracking-wide">자막 파일(srt)만 업로드</h2>
					<p className="mt-2 text-black tracking-wide">Upload or darg & drop your SRT file</p>
					<input id="dropzone-file" type="file" ref={fileInputRef} onChange={handleFileUpload} className="hidden" />
					{fileName &&
						<div className='mt-4 break-all'>
							<p className='font-bold text-blue-500'>{fileName}</p>
						</div>
					}
				</section>
				<div className="sm:flex sm:flex-row sm:flex-wrap sm:space-x-4 sm:w-auto sm:items-center w-full ">
					<div className="sm:m-0 mb-4 mt-4">
						<label htmlFor="customPath" className='sm:text-base'>도메인</label>
						<div className="flex flex-row items-center">
							<div className='sm:block relative w-full'>
								<input type="text" value="subvoca.com" readOnly className='rounded-xl border border-black bg-[#f5f6f7] p-4 sm:w-64 w-full pr-8 text-[#828387]' />
								<FaLock className='absolute right-2 top-1/2 transform -translate-y-1/2' />
							</div>
							<div className="inline sm:hidden m-4 sm:m-0">
								/s
							</div>
						</div>
					</div>
					<p className="hidden sm:inline mt-5">/s</p>
					<div>
						<label htmlFor="customPath" className='text-base'>식별자 입력</label>
						<div className="relative">
							<input type="text" value={inputValue} onChange={handleInputChange} placeholder="example" className='rounded-xl border border-black text-[#828387] p-4 sm:w-64 w-full pr-8' />
						</div>
					</div>
					<div className="">
						<div>&nbsp;</div>
						<button
							type="button"
							onClick={extractSubtitle}
							className="rounded-xl text-center text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium hounded-lg text-sm dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700 p-4 w-full sm:w-auto"
						>
							추출하기
						</button>
					</div>
				</div>
				<div className='mt-4 text-xl'>
					{url &&
						<div>
							이 url로 다시 돌아가면 추출된 문장을 확인할 수 있습니다.
							<a href={`/s/${url}`} target='_new' className="font-medium text-blue-600 dark:text-blue-500 hover:underline"> {url}</a>
						</div>
					}
				</div>
			</div>
		</div>
	);
}

export default Home;