import { useNavigate } from 'react-router-dom';

function NotFound({ errorCode }) {
  const navigate = useNavigate();

  const goToHomePage = () => {
    navigate('/');
  };

  return (
    <div className='flex flex-col justify-center items-center h-screen bg-[#E0E8F1]/50'>
      <p className="text-6xl font-bold bg-gradient-to-r from-blue-400 to-cyan-950 bg-clip-text text-transparent mb-4">Ooops!</p>
      <div className='text-center space-y-1'>
        <h1>Error {errorCode}</h1>
        <p>The page you are looking for does not exist.</p>
        <button onClick={goToHomePage} className='px-4 py-1 bg-blue-500 text-white rounded'>Go to Home Page</button>
      </div>
    </div>
  );
}
export default NotFound;